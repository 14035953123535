exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advice-js": () => import("./../../../src/pages/advice.js" /* webpackChunkName: "component---src-pages-advice-js" */),
  "component---src-pages-answers-js": () => import("./../../../src/pages/answers.js" /* webpackChunkName: "component---src-pages-answers-js" */),
  "component---src-pages-call-js": () => import("./../../../src/pages/call.js" /* webpackChunkName: "component---src-pages-call-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-complete-js": () => import("./../../../src/pages/complete.js" /* webpackChunkName: "component---src-pages-complete-js" */),
  "component---src-pages-components-js": () => import("./../../../src/pages/components.js" /* webpackChunkName: "component---src-pages-components-js" */),
  "component---src-pages-data-js": () => import("./../../../src/pages/data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-face-to-face-js": () => import("./../../../src/pages/face-to-face.js" /* webpackChunkName: "component---src-pages-face-to-face-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-longterm-support-js": () => import("./../../../src/pages/longterm-support.js" /* webpackChunkName: "component---src-pages-longterm-support-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-urgent-support-js": () => import("./../../../src/pages/urgent-support.js" /* webpackChunkName: "component---src-pages-urgent-support-js" */),
  "component---src-pages-video-call-js": () => import("./../../../src/pages/video-call.js" /* webpackChunkName: "component---src-pages-video-call-js" */)
}

